@layer tokens {
    :where(:root, :host) {
  --borders-none: none;
  --easings-default: cubic-bezier(0.4, 0, 0.2, 1);
  --easings-linear: linear;
  --easings-in: cubic-bezier(0.4, 0, 1, 1);
  --easings-out: cubic-bezier(0, 0, 0.2, 1);
  --easings-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --durations-fastest: 50ms;
  --durations-faster: 100ms;
  --durations-fast: 150ms;
  --durations-normal: 200ms;
  --durations-slow: 300ms;
  --durations-slower: 400ms;
  --durations-slowest: 500ms;
  --font-weights-thin: 100;
  --font-weights-extralight: 200;
  --font-weights-light: 300;
  --font-weights-normal: 400;
  --font-weights-medium: 500;
  --font-weights-semibold: 600;
  --font-weights-bold: 700;
  --font-weights-extrabold: 800;
  --font-weights-black: 900;
  --line-heights-none: 1;
  --line-heights-tight: 1.25;
  --line-heights-snug: 1.375;
  --line-heights-normal: 1.5;
  --line-heights-relaxed: 1.625;
  --line-heights-loose: 2;
  --fonts-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --fonts-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --fonts-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --letter-spacings-tighter: -0.05em;
  --letter-spacings-tight: -0.025em;
  --letter-spacings-normal: 0em;
  --letter-spacings-wide: 0.025em;
  --letter-spacings-wider: 0.05em;
  --letter-spacings-widest: 0.1em;
  --font-sizes-2xs: 0.5rem;
  --font-sizes-xs: 0.75rem;
  --font-sizes-sm: 0.875rem;
  --font-sizes-md: 1rem;
  --font-sizes-lg: 1.125rem;
  --font-sizes-xl: 1.25rem;
  --font-sizes-2xl: 1.5rem;
  --font-sizes-3xl: 1.875rem;
  --font-sizes-4xl: 2.25rem;
  --font-sizes-5xl: 3rem;
  --font-sizes-6xl: 3.75rem;
  --font-sizes-7xl: 4.5rem;
  --font-sizes-8xl: 6rem;
  --font-sizes-9xl: 8rem;
  --shadows-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadows-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadows-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadows-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadows-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --shadows-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --shadows-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --colors-current: currentColor;
  --colors-black: #000;
  --colors-white: #fff;
  --colors-transparent: rgb(0 0 0 / 0);
  --colors-rose-50: #fff1f2;
  --colors-rose-100: #ffe4e6;
  --colors-rose-200: #fecdd3;
  --colors-rose-300: #fda4af;
  --colors-rose-400: #fb7185;
  --colors-rose-500: #f43f5e;
  --colors-rose-600: #e11d48;
  --colors-rose-700: #be123c;
  --colors-rose-800: #9f1239;
  --colors-rose-900: #881337;
  --colors-rose-950: #4c0519;
  --colors-pink-50: #fdf2f8;
  --colors-pink-100: #fce7f3;
  --colors-pink-200: #fbcfe8;
  --colors-pink-300: #f9a8d4;
  --colors-pink-400: #f472b6;
  --colors-pink-500: #ec4899;
  --colors-pink-600: #db2777;
  --colors-pink-700: #be185d;
  --colors-pink-800: #9d174d;
  --colors-pink-900: #831843;
  --colors-pink-950: #500724;
  --colors-fuchsia-50: #fdf4ff;
  --colors-fuchsia-100: #fae8ff;
  --colors-fuchsia-200: #f5d0fe;
  --colors-fuchsia-300: #f0abfc;
  --colors-fuchsia-400: #e879f9;
  --colors-fuchsia-500: #d946ef;
  --colors-fuchsia-600: #c026d3;
  --colors-fuchsia-700: #a21caf;
  --colors-fuchsia-800: #86198f;
  --colors-fuchsia-900: #701a75;
  --colors-fuchsia-950: #4a044e;
  --colors-purple-50: #faf5ff;
  --colors-purple-100: #f3e8ff;
  --colors-purple-200: #e9d5ff;
  --colors-purple-300: #d8b4fe;
  --colors-purple-400: #c084fc;
  --colors-purple-500: #a855f7;
  --colors-purple-600: #9333ea;
  --colors-purple-700: #7e22ce;
  --colors-purple-800: #6b21a8;
  --colors-purple-900: #581c87;
  --colors-purple-950: #3b0764;
  --colors-violet-50: #f5f3ff;
  --colors-violet-100: #ede9fe;
  --colors-violet-200: #ddd6fe;
  --colors-violet-300: #c4b5fd;
  --colors-violet-400: #a78bfa;
  --colors-violet-500: #8b5cf6;
  --colors-violet-600: #7c3aed;
  --colors-violet-700: #6d28d9;
  --colors-violet-800: #5b21b6;
  --colors-violet-900: #4c1d95;
  --colors-violet-950: #2e1065;
  --colors-indigo-50: #eef2ff;
  --colors-indigo-100: #e0e7ff;
  --colors-indigo-200: #c7d2fe;
  --colors-indigo-300: #a5b4fc;
  --colors-indigo-400: #818cf8;
  --colors-indigo-500: #6366f1;
  --colors-indigo-600: #4f46e5;
  --colors-indigo-700: #4338ca;
  --colors-indigo-800: #3730a3;
  --colors-indigo-900: #312e81;
  --colors-indigo-950: #1e1b4b;
  --colors-blue-50: #eff6ff;
  --colors-blue-100: #dbeafe;
  --colors-blue-200: #bfdbfe;
  --colors-blue-300: #93c5fd;
  --colors-blue-400: #60a5fa;
  --colors-blue-500: #3b82f6;
  --colors-blue-600: #2563eb;
  --colors-blue-700: #1d4ed8;
  --colors-blue-800: #1e40af;
  --colors-blue-900: #1e3a8a;
  --colors-blue-950: #172554;
  --colors-sky-50: #f0f9ff;
  --colors-sky-100: #e0f2fe;
  --colors-sky-200: #bae6fd;
  --colors-sky-300: #7dd3fc;
  --colors-sky-400: #38bdf8;
  --colors-sky-500: #0ea5e9;
  --colors-sky-600: #0284c7;
  --colors-sky-700: #0369a1;
  --colors-sky-800: #075985;
  --colors-sky-900: #0c4a6e;
  --colors-sky-950: #082f49;
  --colors-cyan-50: #ecfeff;
  --colors-cyan-100: #cffafe;
  --colors-cyan-200: #a5f3fc;
  --colors-cyan-300: #67e8f9;
  --colors-cyan-400: #22d3ee;
  --colors-cyan-500: #06b6d4;
  --colors-cyan-600: #0891b2;
  --colors-cyan-700: #0e7490;
  --colors-cyan-800: #155e75;
  --colors-cyan-900: #164e63;
  --colors-cyan-950: #083344;
  --colors-teal-50: #f0fdfa;
  --colors-teal-100: #ccfbf1;
  --colors-teal-200: #99f6e4;
  --colors-teal-300: #5eead4;
  --colors-teal-400: #2dd4bf;
  --colors-teal-500: #14b8a6;
  --colors-teal-600: #0d9488;
  --colors-teal-700: #0f766e;
  --colors-teal-800: #115e59;
  --colors-teal-900: #134e4a;
  --colors-teal-950: #042f2e;
  --colors-emerald-50: #ecfdf5;
  --colors-emerald-100: #d1fae5;
  --colors-emerald-200: #a7f3d0;
  --colors-emerald-300: #6ee7b7;
  --colors-emerald-400: #34d399;
  --colors-emerald-500: #10b981;
  --colors-emerald-600: #059669;
  --colors-emerald-700: #047857;
  --colors-emerald-800: #065f46;
  --colors-emerald-900: #064e3b;
  --colors-emerald-950: #022c22;
  --colors-green-50: #f0fdf4;
  --colors-green-100: #dcfce7;
  --colors-green-200: #bbf7d0;
  --colors-green-300: #86efac;
  --colors-green-400: #4ade80;
  --colors-green-500: #22c55e;
  --colors-green-600: #16a34a;
  --colors-green-700: #15803d;
  --colors-green-800: #166534;
  --colors-green-900: #14532d;
  --colors-green-950: #052e16;
  --colors-lime-50: #f7fee7;
  --colors-lime-100: #ecfccb;
  --colors-lime-200: #d9f99d;
  --colors-lime-300: #bef264;
  --colors-lime-400: #a3e635;
  --colors-lime-500: #84cc16;
  --colors-lime-600: #65a30d;
  --colors-lime-700: #4d7c0f;
  --colors-lime-800: #3f6212;
  --colors-lime-900: #365314;
  --colors-lime-950: #1a2e05;
  --colors-yellow-50: #fefce8;
  --colors-yellow-100: #fef9c3;
  --colors-yellow-200: #fef08a;
  --colors-yellow-300: #fde047;
  --colors-yellow-400: #facc15;
  --colors-yellow-500: #eab308;
  --colors-yellow-600: #ca8a04;
  --colors-yellow-700: #a16207;
  --colors-yellow-800: #854d0e;
  --colors-yellow-900: #713f12;
  --colors-yellow-950: #422006;
  --colors-amber-50: #fffbeb;
  --colors-amber-100: #fef3c7;
  --colors-amber-200: #fde68a;
  --colors-amber-300: #fcd34d;
  --colors-amber-400: #fbbf24;
  --colors-amber-500: #f59e0b;
  --colors-amber-600: #d97706;
  --colors-amber-700: #b45309;
  --colors-amber-800: #92400e;
  --colors-amber-900: #78350f;
  --colors-amber-950: #451a03;
  --colors-orange-50: #fff7ed;
  --colors-orange-100: #ffedd5;
  --colors-orange-200: #fed7aa;
  --colors-orange-300: #fdba74;
  --colors-orange-400: #fb923c;
  --colors-orange-500: #f97316;
  --colors-orange-600: #ea580c;
  --colors-orange-700: #c2410c;
  --colors-orange-800: #9a3412;
  --colors-orange-900: #7c2d12;
  --colors-orange-950: #431407;
  --colors-red-50: #fef2f2;
  --colors-red-100: #fee2e2;
  --colors-red-200: #fecaca;
  --colors-red-300: #fca5a5;
  --colors-red-400: #f87171;
  --colors-red-500: #ef4444;
  --colors-red-600: #dc2626;
  --colors-red-700: #b91c1c;
  --colors-red-800: #991b1b;
  --colors-red-900: #7f1d1d;
  --colors-red-950: #450a0a;
  --colors-neutral-50: #fafafa;
  --colors-neutral-100: #f5f5f5;
  --colors-neutral-200: #e5e5e5;
  --colors-neutral-300: #d4d4d4;
  --colors-neutral-400: #a3a3a3;
  --colors-neutral-500: #737373;
  --colors-neutral-600: #525252;
  --colors-neutral-700: #404040;
  --colors-neutral-800: #262626;
  --colors-neutral-900: #171717;
  --colors-neutral-950: #0a0a0a;
  --colors-stone-50: #fafaf9;
  --colors-stone-100: #f5f5f4;
  --colors-stone-200: #e7e5e4;
  --colors-stone-300: #d6d3d1;
  --colors-stone-400: #a8a29e;
  --colors-stone-500: #78716c;
  --colors-stone-600: #57534e;
  --colors-stone-700: #44403c;
  --colors-stone-800: #292524;
  --colors-stone-900: #1c1917;
  --colors-stone-950: #0c0a09;
  --colors-zinc-50: #fafafa;
  --colors-zinc-100: #f4f4f5;
  --colors-zinc-200: #e4e4e7;
  --colors-zinc-300: #d4d4d8;
  --colors-zinc-400: #a1a1aa;
  --colors-zinc-500: #71717a;
  --colors-zinc-600: #52525b;
  --colors-zinc-700: #3f3f46;
  --colors-zinc-800: #27272a;
  --colors-zinc-900: #18181b;
  --colors-zinc-950: #09090b;
  --colors-gray-50: #f9fafb;
  --colors-gray-100: #f3f4f6;
  --colors-gray-200: #e5e7eb;
  --colors-gray-300: #d1d5db;
  --colors-gray-400: #9ca3af;
  --colors-gray-500: #6b7280;
  --colors-gray-600: #4b5563;
  --colors-gray-700: #374151;
  --colors-gray-800: #1f2937;
  --colors-gray-900: #111827;
  --colors-gray-950: #030712;
  --colors-slate-50: #f8fafc;
  --colors-slate-100: #f1f5f9;
  --colors-slate-200: #e2e8f0;
  --colors-slate-300: #cbd5e1;
  --colors-slate-400: #94a3b8;
  --colors-slate-500: #64748b;
  --colors-slate-600: #475569;
  --colors-slate-700: #334155;
  --colors-slate-800: #1e293b;
  --colors-slate-900: #0f172a;
  --colors-slate-950: #020617;
  --blurs-sm: 4px;
  --blurs-base: 8px;
  --blurs-md: 12px;
  --blurs-lg: 16px;
  --blurs-xl: 24px;
  --blurs-2xl: 40px;
  --blurs-3xl: 64px;
  --spacing-0: 0rem;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-28: 7rem;
  --spacing-32: 8rem;
  --spacing-36: 9rem;
  --spacing-40: 10rem;
  --spacing-44: 11rem;
  --spacing-48: 12rem;
  --spacing-52: 13rem;
  --spacing-56: 14rem;
  --spacing-60: 15rem;
  --spacing-64: 16rem;
  --spacing-72: 18rem;
  --spacing-80: 20rem;
  --spacing-96: 24rem;
  --spacing-0\.5: 0.125rem;
  --spacing-1\.5: 0.375rem;
  --spacing-2\.5: 0.625rem;
  --spacing-3\.5: 0.875rem;
  --sizes-0: 0rem;
  --sizes-1: 0.25rem;
  --sizes-2: 0.5rem;
  --sizes-3: 0.75rem;
  --sizes-4: 1rem;
  --sizes-5: 1.25rem;
  --sizes-6: 1.5rem;
  --sizes-7: 1.75rem;
  --sizes-8: 2rem;
  --sizes-9: 2.25rem;
  --sizes-10: 2.5rem;
  --sizes-11: 2.75rem;
  --sizes-12: 3rem;
  --sizes-14: 3.5rem;
  --sizes-16: 4rem;
  --sizes-20: 5rem;
  --sizes-24: 6rem;
  --sizes-28: 7rem;
  --sizes-32: 8rem;
  --sizes-36: 9rem;
  --sizes-40: 10rem;
  --sizes-44: 11rem;
  --sizes-48: 12rem;
  --sizes-52: 13rem;
  --sizes-56: 14rem;
  --sizes-60: 15rem;
  --sizes-64: 16rem;
  --sizes-72: 18rem;
  --sizes-80: 20rem;
  --sizes-96: 24rem;
  --sizes-0\.5: 0.125rem;
  --sizes-1\.5: 0.375rem;
  --sizes-2\.5: 0.625rem;
  --sizes-3\.5: 0.875rem;
  --sizes-xs: 20rem;
  --sizes-sm: 24rem;
  --sizes-md: 28rem;
  --sizes-lg: 32rem;
  --sizes-xl: 36rem;
  --sizes-2xl: 42rem;
  --sizes-3xl: 48rem;
  --sizes-4xl: 56rem;
  --sizes-5xl: 64rem;
  --sizes-6xl: 72rem;
  --sizes-7xl: 80rem;
  --sizes-8xl: 90rem;
  --sizes-prose: 65ch;
  --sizes-full: 100%;
  --sizes-min: min-content;
  --sizes-max: max-content;
  --sizes-fit: fit-content;
  --sizes-breakpoint-sm: 640px;
  --sizes-breakpoint-md: 768px;
  --sizes-breakpoint-lg: 1024px;
  --sizes-breakpoint-xl: 1280px;
  --sizes-breakpoint-2xl: 1536px;
  --animations-spin: spin 1s linear infinite;
  --animations-ping: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  --animations-pulse: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  --animations-bounce: bounce 1s infinite;
  --radii-xs: 0.125rem;
  --radii-sm: 0.25rem;
  --radii-md: 1rem;
  --radii-lg: 1.75rem;
  --radii-xl: 3rem;
  --radii-2xl: 4rem;
  --radii-3xl: 6.25rem;
  --radii-full: 9999px;
  --z-index-hide: -1;
  --z-index-tooltip: 100;
  --z-index-modal: 100;
  --z-index-menu: 100;
  --z-index-overlay: 300;
  --z-index-overlay-content: 301;
  --breakpoints-sm: 640px;
  --breakpoints-md: 768px;
  --breakpoints-lg: 1024px;
  --breakpoints-xl: 1280px;
  --breakpoints-2xl: 1536px;
  --colors-esa-primary: #0c3d4a;
  --colors-esa-primary008: #0c3d4a14;
  --colors-esa-primary012: #0c3d4a1f;
  --colors-esa-primary038: #0c3d4a61;
  --colors-esa-primary090: #0c3d4ae6;
  --colors-esa-on_primary: #ffffff;
  --colors-esa-on_primary008: #ffffff14;
  --colors-esa-on_primary012: #ffffff1f;
  --colors-esa-on_primary038: #ffffff61;
  --colors-esa-on_primary090: #ffffffe6;
  --colors-esa-primary_light: #174e5d;
  --colors-esa-primary_light008: #174e5d14;
  --colors-esa-primary_light012: #174e5d1f;
  --colors-esa-primary_light038: #174e5d61;
  --colors-esa-primary_light090: #174e5de6;
  --colors-esa-primary_dark: #083440;
  --colors-esa-primary_dark008: #08344014;
  --colors-esa-primary_dark012: #0834401f;
  --colors-esa-primary_dark038: #08344061;
  --colors-esa-primary_dark090: #083440e6;
  --colors-esa-secondary: #5D8079;
  --colors-esa-secondary008: #5D807914;
  --colors-esa-secondary012: #5D80791f;
  --colors-esa-secondary038: #5D807961;
  --colors-esa-secondary090: #5D8079e6;
  --colors-esa-on_secondary: #ffffff;
  --colors-esa-on_secondary008: #ffffff14;
  --colors-esa-on_secondary012: #ffffff1f;
  --colors-esa-on_secondary038: #ffffff61;
  --colors-esa-on_secondary090: #ffffffe6;
  --colors-esa-background: #ffffff;
  --colors-esa-background008: #ffffff14;
  --colors-esa-background012: #ffffff1f;
  --colors-esa-background038: #ffffff61;
  --colors-esa-background090: #ffffffe6;
  --colors-esa-border: #cfd7e4;
  --colors-esa-border008: #cfd7e414;
  --colors-esa-border012: #cfd7e41f;
  --colors-esa-border038: #cfd7e461;
  --colors-esa-border090: #cfd7e4e6;
  --colors-esa-footer: #CBD5E1;
  --colors-esa-footer008: #CBD5E114;
  --colors-esa-footer012: #CBD5E11f;
  --colors-esa-footer038: #CBD5E161;
  --colors-esa-footer090: #CBD5E1e6;
  --colors-esa-surface: #f5f5f5;
  --colors-esa-surface008: #f5f5f514;
  --colors-esa-surface012: #f5f5f51f;
  --colors-esa-surface038: #f5f5f561;
  --colors-esa-surface090: #f5f5f5e6;
  --colors-esa-on_surface: #0c3d4a;
  --colors-esa-on_surface008: #0c3d4a14;
  --colors-esa-on_surface012: #0c3d4a1f;
  --colors-esa-on_surface038: #0c3d4a61;
  --colors-esa-on_surface090: #0c3d4ae6;
  --colors-esa-menu_background: #e6faf6;
  --colors-esa-menu_background008: #e6faf614;
  --colors-esa-menu_background012: #e6faf61f;
  --colors-esa-menu_background038: #e6faf661;
  --colors-esa-menu_background090: #e6faf6e6;
  --colors-esa-error_background: #ff000052;
  --colors-esa-error_background008: #ff00005214;
  --colors-esa-error_background012: #ff0000521f;
  --colors-esa-error_background038: #ff00005261;
  --colors-esa-error_background090: #ff000052e6;
  --colors-esa-ivory: #fbfff1;
  --colors-esa-ivory008: #fbfff114;
  --colors-esa-ivory012: #fbfff11f;
  --colors-esa-ivory038: #fbfff161;
  --colors-esa-ivory090: #fbfff1e6;
  --colors-button-filled-background: #0c3d4a;
  --colors-button-text-color: #0c3d4a;
  --colors-button-elevated-hover-dark-background: #0c3d4a1f;
  --colors-button-elevated-pressed-dark-background: #0c3d4a1f;
  --colors-button-elevated-focus-dark-background: #0c3d4a1f;
  --colors-button-tonal-background: #5D80791f;
  --colors-elevated-background: #e6faf6;
  --colors-form-background: #5D80791f;
  --colors-form-border-color: #0c3d4a;
  --colors-surface-low: #fbfff1;
  --colors-surface-text: #0c3d4a;
  --colors-type-color: #0c3d4a;
  --font-sizes-button-sm: 0.75rem;
  --font-sizes-button-md: 0.875rem;
  --font-sizes-button-lg: 1rem;
  --font-sizes-type-h1-base: 2.5rem;
  --font-sizes-type-h1-sm: 3rem;
  --font-sizes-type-h1-md: 4.375rem;
  --font-sizes-type-h2-base: 1.875rem;
  --font-sizes-type-h2-sm: 2.25rem;
  --font-sizes-type-h2-md: 3rem;
  --font-sizes-type-h3-base: 1.5rem;
  --font-sizes-type-h3-sm: 1.875rem;
  --font-sizes-type-h3-md: 2.25rem;
  --font-sizes-type-h4-base: 1.25rem;
  --font-sizes-type-h4-sm: 1.5rem;
  --font-sizes-type-h4-md: 1.875rem;
  --font-sizes-type-h5-base: 1rem;
  --font-sizes-type-h5-sm: 1.25rem;
  --font-sizes-type-h5-md: 1.5rem;
  --font-sizes-type-h6-base: 0.875rem;
  --font-sizes-type-h6-sm: 1rem;
  --font-sizes-type-h6-md: 1.25rem;
  --font-sizes-type-subtitle1-base: 20px;
  --font-sizes-type-subtitle1-sm: 20px;
  --font-sizes-type-subtitle1-md: 20px;
  --font-sizes-type-subtitle2-base: 0.875rem;
  --font-sizes-type-subtitle2-sm: 1rem;
  --font-sizes-type-subtitle2-md: 1.125rem;
  --font-sizes-type-body1-base: 1rem;
  --font-sizes-type-body1-sm: 1.125rem;
  --font-sizes-type-body1-md: 1.25rem;
  --font-sizes-type-body2-base: 0.875rem;
  --font-sizes-type-body2-sm: 1rem;
  --font-sizes-type-body2-md: 1.125rem;
  --font-sizes-type-caption-base: 0.75rem;
  --font-sizes-type-caption-sm: 0.75rem;
  --font-sizes-type-caption-md: 0.75rem;
  --gradients-breakup: linear-gradient(180deg, rgba(240, 185, 43, 0.48) 0%, rgba(35, 95, 77, 0.47) 100%);
  --gradients-surface: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(240, 240, 240) 100%);
  --line-heights-type-h1-base: 2.5rem;
  --line-heights-type-h1-sm: 3rem;
  --line-heights-type-h1-md: 4.375rem;
  --line-heights-type-h2-base: 1.875rem;
  --line-heights-type-h2-sm: 2.25rem;
  --line-heights-type-h2-md: 3rem;
  --line-heights-type-h3-base: 1.5rem;
  --line-heights-type-h3-sm: 1.875rem;
  --line-heights-type-h3-md: 2.25rem;
  --line-heights-type-h4-base: 1.25rem;
  --line-heights-type-h4-sm: 1.5rem;
  --line-heights-type-h4-md: 1.875rem;
  --line-heights-type-h5-base: 1rem;
  --line-heights-type-h5-sm: 1.25rem;
  --line-heights-type-h5-md: 1.5rem;
  --line-heights-type-h6-base: 0.875rem;
  --line-heights-type-h6-sm: 1rem;
  --line-heights-type-h6-md: 1.25rem;
  --line-heights-type-subtitle1-base: 20px;
  --line-heights-type-subtitle1-sm: 20px;
  --line-heights-type-subtitle1-md: 20px;
  --line-heights-type-subtitle2-base: 0.875rem;
  --line-heights-type-subtitle2-sm: 1rem;
  --line-heights-type-subtitle2-md: 1.125rem;
  --line-heights-type-body1-base: 1rem;
  --line-heights-type-body1-sm: 1.125rem;
  --line-heights-type-body1-md: 1.25rem;
  --line-heights-type-body2-base: 0.875rem;
  --line-heights-type-body2-sm: 1rem;
  --line-heights-type-body2-md: 1.125rem;
  --line-heights-type-caption-base: 0.75rem;
  --line-heights-type-caption-sm: 0.875rem;
  --line-heights-type-caption-md: 1rem;
  --shadows-button-filled-hover-box-shadow: 2px 2px 0px 1px #5D8079;
  --shadows-card-box-shadow: 2px 2px 0px 1px #5D8079;
  --sizes-width-parent: 1600px;
  --sizes-width-child: 1400px;
  --sizes-max-width-parent: calc(100vw);
  --sizes-max-width-body-child-mobile: calc(100vw - 0rem);
  --sizes-max-width-body-child-sm: calc(100vw - 4rem);
  --sizes-max-width-child-mobile: calc(100vw - 2rem);
  --sizes-max-width-child-sm: calc(100vw - 4rem);
  --sizes-max-minus-scrollbar-mobile: calc(100vw - 0rem);
  --sizes-max-minus-scrollbar-sm: calc(100vw - 17px);
  --spacing-button-icon-padding-y: 10px;
  --spacing-button-icon-padding-left: 16px;
  --spacing-button-icon-padding-right: 24px;
  --spacing-button-text-padding-y: 10px;
  --spacing-button-text-padding-x: 12px;
  --spacing-button-icon-text-padding-y: 10px;
  --spacing-button-icon-text-padding-left: 12px;
  --spacing-button-icon-text-padding-right: 24px;
  --spacing-button-sm-padding-x: 10px;
  --spacing-button-sm-padding-y: 6px;
  --spacing-button-md-padding-x: 24px;
  --spacing-button-md-padding-y: 10px;
  --spacing-button-lg-padding-x: 22px;
  --spacing-button-lg-padding-y: 18px;
  --radii-faq: 8px;
  --radii-button-sm: 16px;
  --radii-button-md: 24px;
  --radii-button-lg: 999px
}

[data-color-mode=light] {
  --colors-m3-primary: #6750A4;
  --colors-m3-primary008: #6750A414;
  --colors-m3-primary012: #6750A41f;
  --colors-m3-primary038: #6750A461;
  --colors-m3-primary090: #6750A4e6;
  --colors-m3-on-primary: #FFFFFF;
  --colors-m3-on-primary008: #FFFFFF14;
  --colors-m3-on-primary012: #FFFFFF1f;
  --colors-m3-on-primary038: #FFFFFF61;
  --colors-m3-on-primary090: #FFFFFFe6;
  --colors-m3-primary-container: #EADDFF;
  --colors-m3-primary-container008: #EADDFF14;
  --colors-m3-primary-container012: #EADDFF1f;
  --colors-m3-primary-container038: #EADDFF61;
  --colors-m3-primary-container090: #EADDFFe6;
  --colors-m3-on-primary-container: #21005D;
  --colors-m3-on-primary-container008: #21005D14;
  --colors-m3-on-primary-container012: #21005D1f;
  --colors-m3-on-primary-container038: #21005D61;
  --colors-m3-on-primary-container090: #21005De6;
  --colors-m3-primary-fixed: #EADDFF;
  --colors-m3-primary-fixed008: #EADDFF14;
  --colors-m3-primary-fixed012: #EADDFF1f;
  --colors-m3-primary-fixed038: #EADDFF61;
  --colors-m3-primary-fixed090: #EADDFFe6;
  --colors-m3-on-primary-fixed: #21005D;
  --colors-m3-on-primary-fixed008: #21005D14;
  --colors-m3-on-primary-fixed012: #21005D1f;
  --colors-m3-on-primary-fixed038: #21005D61;
  --colors-m3-on-primary-fixed090: #21005De6;
  --colors-m3-primary-fixed-dim: #D0BCFF;
  --colors-m3-primary-fixed-dim008: #D0BCFF14;
  --colors-m3-primary-fixed-dim012: #D0BCFF1f;
  --colors-m3-primary-fixed-dim038: #D0BCFF61;
  --colors-m3-primary-fixed-dim090: #D0BCFFe6;
  --colors-m3-on-primary-fixed-variant: #4F378B;
  --colors-m3-on-primary-fixed-variant008: #4F378B14;
  --colors-m3-on-primary-fixed-variant012: #4F378B1f;
  --colors-m3-on-primary-fixed-variant038: #4F378B61;
  --colors-m3-on-primary-fixed-variant090: #4F378Be6;
  --colors-m3-secondary: #625B71;
  --colors-m3-secondary008: #625B7114;
  --colors-m3-secondary012: #625B711f;
  --colors-m3-secondary038: #625B7161;
  --colors-m3-secondary090: #625B71e6;
  --colors-m3-on-secondary: #FFFFFF;
  --colors-m3-on-secondary008: #FFFFFF14;
  --colors-m3-on-secondary012: #FFFFFF1f;
  --colors-m3-on-secondary038: #FFFFFF61;
  --colors-m3-on-secondary090: #FFFFFFe6;
  --colors-m3-secondary-container: #E8DEF8;
  --colors-m3-secondary-container008: #E8DEF814;
  --colors-m3-secondary-container012: #E8DEF81f;
  --colors-m3-secondary-container038: #E8DEF861;
  --colors-m3-secondary-container090: #E8DEF8e6;
  --colors-m3-on-secondary-container: #1D192B;
  --colors-m3-on-secondary-container008: #1D192B14;
  --colors-m3-on-secondary-container012: #1D192B1f;
  --colors-m3-on-secondary-container038: #1D192B61;
  --colors-m3-on-secondary-container090: #1D192Be6;
  --colors-m3-secondary-fixed: #E8DEF8;
  --colors-m3-secondary-fixed008: #E8DEF814;
  --colors-m3-secondary-fixed012: #E8DEF81f;
  --colors-m3-secondary-fixed038: #E8DEF861;
  --colors-m3-secondary-fixed090: #E8DEF8e6;
  --colors-m3-on-secondary-fixed: #1D192B;
  --colors-m3-on-secondary-fixed008: #1D192B14;
  --colors-m3-on-secondary-fixed012: #1D192B1f;
  --colors-m3-on-secondary-fixed038: #1D192B61;
  --colors-m3-on-secondary-fixed090: #1D192Be6;
  --colors-m3-secondary-fixed-dim: #CCC2DC;
  --colors-m3-secondary-fixed-dim008: #CCC2DC14;
  --colors-m3-secondary-fixed-dim012: #CCC2DC1f;
  --colors-m3-secondary-fixed-dim038: #CCC2DC61;
  --colors-m3-secondary-fixed-dim090: #CCC2DCe6;
  --colors-m3-on-secondary-fixed-variant: #4A4458;
  --colors-m3-on-secondary-fixed-variant008: #4A445814;
  --colors-m3-on-secondary-fixed-variant012: #4A44581f;
  --colors-m3-on-secondary-fixed-variant038: #4A445861;
  --colors-m3-on-secondary-fixed-variant090: #4A4458e6;
  --colors-m3-tertiary: #7D5260;
  --colors-m3-tertiary008: #7D526014;
  --colors-m3-tertiary012: #7D52601f;
  --colors-m3-tertiary038: #7D526061;
  --colors-m3-tertiary090: #7D5260e6;
  --colors-m3-on-tertiary: #FFFFFF;
  --colors-m3-on-tertiary008: #FFFFFF14;
  --colors-m3-on-tertiary012: #FFFFFF1f;
  --colors-m3-on-tertiary038: #FFFFFF61;
  --colors-m3-on-tertiary090: #FFFFFFe6;
  --colors-m3-tertiary-container: #FFD8E4;
  --colors-m3-tertiary-container008: #FFD8E414;
  --colors-m3-tertiary-container012: #FFD8E41f;
  --colors-m3-tertiary-container038: #FFD8E461;
  --colors-m3-tertiary-container090: #FFD8E4e6;
  --colors-m3-on-tertiary-container: #31111D;
  --colors-m3-on-tertiary-container008: #31111D14;
  --colors-m3-on-tertiary-container012: #31111D1f;
  --colors-m3-on-tertiary-container038: #31111D61;
  --colors-m3-on-tertiary-container090: #31111De6;
  --colors-m3-tertiary-fixed: #FFD8E4;
  --colors-m3-tertiary-fixed008: #FFD8E414;
  --colors-m3-tertiary-fixed012: #FFD8E41f;
  --colors-m3-tertiary-fixed038: #FFD8E461;
  --colors-m3-tertiary-fixed090: #FFD8E4e6;
  --colors-m3-on-tertiary-fixed: #31111D;
  --colors-m3-on-tertiary-fixed008: #31111D14;
  --colors-m3-on-tertiary-fixed012: #31111D1f;
  --colors-m3-on-tertiary-fixed038: #31111D61;
  --colors-m3-on-tertiary-fixed090: #31111De6;
  --colors-m3-tertiary-fixed-dim: #EFB8C8;
  --colors-m3-tertiary-fixed-dim008: #EFB8C814;
  --colors-m3-tertiary-fixed-dim012: #EFB8C81f;
  --colors-m3-tertiary-fixed-dim038: #EFB8C861;
  --colors-m3-tertiary-fixed-dim090: #EFB8C8e6;
  --colors-m3-on-tertiary-fixed-variant: #633B48;
  --colors-m3-on-tertiary-fixed-variant008: #633B4814;
  --colors-m3-on-tertiary-fixed-variant012: #633B481f;
  --colors-m3-on-tertiary-fixed-variant038: #633B4861;
  --colors-m3-on-tertiary-fixed-variant090: #633B48e6;
  --colors-m3-error: #B3261E;
  --colors-m3-error008: #B3261E14;
  --colors-m3-error012: #B3261E1f;
  --colors-m3-error038: #B3261E61;
  --colors-m3-error090: #B3261Ee6;
  --colors-m3-on-error: #FFFFFF;
  --colors-m3-on-error008: #FFFFFF14;
  --colors-m3-on-error012: #FFFFFF1f;
  --colors-m3-on-error038: #FFFFFF61;
  --colors-m3-on-error090: #FFFFFFe6;
  --colors-m3-error-container: #F9DEDC;
  --colors-m3-error-container008: #F9DEDC14;
  --colors-m3-error-container012: #F9DEDC1f;
  --colors-m3-error-container038: #F9DEDC61;
  --colors-m3-error-container090: #F9DEDCe6;
  --colors-m3-on-error-container: #410E0B;
  --colors-m3-on-error-container008: #410E0B14;
  --colors-m3-on-error-container012: #410E0B1f;
  --colors-m3-on-error-container038: #410E0B61;
  --colors-m3-on-error-container090: #410E0Be6;
  --colors-m3-outline: #79747E;
  --colors-m3-outline008: #79747E14;
  --colors-m3-outline012: #79747E1f;
  --colors-m3-outline038: #79747E61;
  --colors-m3-outline090: #79747Ee6;
  --colors-m3-background: #FEF7FF;
  --colors-m3-background008: #FEF7FF14;
  --colors-m3-background012: #FEF7FF1f;
  --colors-m3-background038: #FEF7FF61;
  --colors-m3-background090: #FEF7FFe6;
  --colors-m3-on-background: #1D1B20;
  --colors-m3-on-background008: #1D1B2014;
  --colors-m3-on-background012: #1D1B201f;
  --colors-m3-on-background038: #1D1B2061;
  --colors-m3-on-background090: #1D1B20e6;
  --colors-m3-surface: #FEF7FF;
  --colors-m3-surface008: #FEF7FF14;
  --colors-m3-surface012: #FEF7FF1f;
  --colors-m3-surface038: #FEF7FF61;
  --colors-m3-surface090: #FEF7FFe6;
  --colors-m3-on-surface: #1D1B20;
  --colors-m3-on-surface008: #1D1B2014;
  --colors-m3-on-surface012: #1D1B201f;
  --colors-m3-on-surface038: #1D1B2061;
  --colors-m3-on-surface090: #1D1B20e6;
  --colors-m3-surface-variant: #E7E0EC;
  --colors-m3-surface-variant008: #E7E0EC14;
  --colors-m3-surface-variant012: #E7E0EC1f;
  --colors-m3-surface-variant038: #E7E0EC61;
  --colors-m3-surface-variant090: #E7E0ECe6;
  --colors-m3-on-surface-variant: #49454F;
  --colors-m3-on-surface-variant008: #49454F14;
  --colors-m3-on-surface-variant012: #49454F1f;
  --colors-m3-on-surface-variant038: #49454F61;
  --colors-m3-on-surface-variant090: #49454Fe6;
  --colors-m3-inverse-surface: #322F35;
  --colors-m3-inverse-surface008: #322F3514;
  --colors-m3-inverse-surface012: #322F351f;
  --colors-m3-inverse-surface038: #322F3561;
  --colors-m3-inverse-surface090: #322F35e6;
  --colors-m3-inverse-on-surface: #F5EFF7;
  --colors-m3-inverse-on-surface008: #F5EFF714;
  --colors-m3-inverse-on-surface012: #F5EFF71f;
  --colors-m3-inverse-on-surface038: #F5EFF761;
  --colors-m3-inverse-on-surface090: #F5EFF7e6;
  --colors-m3-inverse-primary: #D0BCFF;
  --colors-m3-inverse-primary008: #D0BCFF14;
  --colors-m3-inverse-primary012: #D0BCFF1f;
  --colors-m3-inverse-primary038: #D0BCFF61;
  --colors-m3-inverse-primary090: #D0BCFFe6;
  --colors-m3-shadow: #000000;
  --colors-m3-shadow008: #00000014;
  --colors-m3-shadow012: #0000001f;
  --colors-m3-shadow038: #00000061;
  --colors-m3-shadow090: #000000e6;
  --colors-m3-surface-tint: #6750A4;
  --colors-m3-surface-tint008: #6750A414;
  --colors-m3-surface-tint012: #6750A41f;
  --colors-m3-surface-tint038: #6750A461;
  --colors-m3-surface-tint090: #6750A4e6;
  --colors-m3-outline-variant: #CAC4D0;
  --colors-m3-outline-variant008: #CAC4D014;
  --colors-m3-outline-variant012: #CAC4D01f;
  --colors-m3-outline-variant038: #CAC4D061;
  --colors-m3-outline-variant090: #CAC4D0e6;
  --colors-m3-scrim: #000000;
  --colors-m3-scrim008: #00000014;
  --colors-m3-scrim012: #0000001f;
  --colors-m3-scrim038: #00000061;
  --colors-m3-scrim090: #000000e6;
  --colors-m3-surface-container-highest: #E6E0E9;
  --colors-m3-surface-container-highest008: #E6E0E914;
  --colors-m3-surface-container-highest012: #E6E0E91f;
  --colors-m3-surface-container-highest038: #E6E0E961;
  --colors-m3-surface-container-highest090: #E6E0E9e6;
  --colors-m3-surface-container-high: #ECE6F0;
  --colors-m3-surface-container-high008: #ECE6F014;
  --colors-m3-surface-container-high012: #ECE6F01f;
  --colors-m3-surface-container-high038: #ECE6F061;
  --colors-m3-surface-container-high090: #ECE6F0e6;
  --colors-m3-surface-container: #F3EDF7;
  --colors-m3-surface-container008: #F3EDF714;
  --colors-m3-surface-container012: #F3EDF71f;
  --colors-m3-surface-container038: #F3EDF761;
  --colors-m3-surface-container090: #F3EDF7e6;
  --colors-m3-surface-container-low: #F7F2FA;
  --colors-m3-surface-container-low008: #F7F2FA14;
  --colors-m3-surface-container-low012: #F7F2FA1f;
  --colors-m3-surface-container-low038: #F7F2FA61;
  --colors-m3-surface-container-low090: #F7F2FAe6;
  --colors-m3-surface-container-lowest: #FFFFFF;
  --colors-m3-surface-container-lowest008: #FFFFFF14;
  --colors-m3-surface-container-lowest012: #FFFFFF1f;
  --colors-m3-surface-container-lowest038: #FFFFFF61;
  --colors-m3-surface-container-lowest090: #FFFFFFe6;
  --colors-m3-surface-bright: #FEF7FF;
  --colors-m3-surface-bright008: #FEF7FF14;
  --colors-m3-surface-bright012: #FEF7FF1f;
  --colors-m3-surface-bright038: #FEF7FF61;
  --colors-m3-surface-bright090: #FEF7FFe6;
  --colors-m3-surface-dim: #DED8E1;
  --colors-m3-surface-dim008: #DED8E114;
  --colors-m3-surface-dim012: #DED8E11f;
  --colors-m3-surface-dim038: #DED8E161;
  --colors-m3-surface-dim090: #DED8E1e6;
  --colors-button-elevated-box-shadow: 0px 1px  3px 1px #00000026, 0px 1px  2px 0px #0000004d;
  --shadows-button-elevated-box-shadow: 0px 1px  3px 1px #00000026, 0px 1px  2px 0px #0000004d;
  --shadows-button-elevated-hover-box-shadow: 0px 2px  6px 2px #00000026, 0px 1px  2px 0px #0000004d;
  --shadows-button-tonal-hover-box-shadow: 0px 1px  3px 1px #00000026, 0px 1px  2px 0px #0000004d
}

[data-color-mode=dark] {
  --colors-m3-primary: #D0BCFF;
  --colors-m3-primary008: #D0BCFF14;
  --colors-m3-primary012: #D0BCFF1f;
  --colors-m3-primary038: #D0BCFF61;
  --colors-m3-primary090: #D0BCFFe6;
  --colors-m3-on-primary: #381E72;
  --colors-m3-on-primary008: #381E7214;
  --colors-m3-on-primary012: #381E721f;
  --colors-m3-on-primary038: #381E7261;
  --colors-m3-on-primary090: #381E72e6;
  --colors-m3-primary-container: #4F378B;
  --colors-m3-primary-container008: #4F378B14;
  --colors-m3-primary-container012: #4F378B1f;
  --colors-m3-primary-container038: #4F378B61;
  --colors-m3-primary-container090: #4F378Be6;
  --colors-m3-on-primary-container: #EADDFF;
  --colors-m3-on-primary-container008: #EADDFF14;
  --colors-m3-on-primary-container012: #EADDFF1f;
  --colors-m3-on-primary-container038: #EADDFF61;
  --colors-m3-on-primary-container090: #EADDFFe6;
  --colors-m3-primary-fixed: #EADDFF;
  --colors-m3-primary-fixed008: #EADDFF14;
  --colors-m3-primary-fixed012: #EADDFF1f;
  --colors-m3-primary-fixed038: #EADDFF61;
  --colors-m3-primary-fixed090: #EADDFFe6;
  --colors-m3-on-primary-fixed: #21005D;
  --colors-m3-on-primary-fixed008: #21005D14;
  --colors-m3-on-primary-fixed012: #21005D1f;
  --colors-m3-on-primary-fixed038: #21005D61;
  --colors-m3-on-primary-fixed090: #21005De6;
  --colors-m3-primary-fixed-dim: #D0BCFF;
  --colors-m3-primary-fixed-dim008: #D0BCFF14;
  --colors-m3-primary-fixed-dim012: #D0BCFF1f;
  --colors-m3-primary-fixed-dim038: #D0BCFF61;
  --colors-m3-primary-fixed-dim090: #D0BCFFe6;
  --colors-m3-on-primary-fixed-variant: #4F378B;
  --colors-m3-on-primary-fixed-variant008: #4F378B14;
  --colors-m3-on-primary-fixed-variant012: #4F378B1f;
  --colors-m3-on-primary-fixed-variant038: #4F378B61;
  --colors-m3-on-primary-fixed-variant090: #4F378Be6;
  --colors-m3-secondary: #CCC2DC;
  --colors-m3-secondary008: #CCC2DC14;
  --colors-m3-secondary012: #CCC2DC1f;
  --colors-m3-secondary038: #CCC2DC61;
  --colors-m3-secondary090: #CCC2DCe6;
  --colors-m3-on-secondary: #332D41;
  --colors-m3-on-secondary008: #332D4114;
  --colors-m3-on-secondary012: #332D411f;
  --colors-m3-on-secondary038: #332D4161;
  --colors-m3-on-secondary090: #332D41e6;
  --colors-m3-secondary-container: #4A4458;
  --colors-m3-secondary-container008: #4A445814;
  --colors-m3-secondary-container012: #4A44581f;
  --colors-m3-secondary-container038: #4A445861;
  --colors-m3-secondary-container090: #4A4458e6;
  --colors-m3-on-secondary-container: #E8DEF8;
  --colors-m3-on-secondary-container008: #E8DEF814;
  --colors-m3-on-secondary-container012: #E8DEF81f;
  --colors-m3-on-secondary-container038: #E8DEF861;
  --colors-m3-on-secondary-container090: #E8DEF8e6;
  --colors-m3-secondary-fixed: #E8DEF8;
  --colors-m3-secondary-fixed008: #E8DEF814;
  --colors-m3-secondary-fixed012: #E8DEF81f;
  --colors-m3-secondary-fixed038: #E8DEF861;
  --colors-m3-secondary-fixed090: #E8DEF8e6;
  --colors-m3-on-secondary-fixed: #1D192B;
  --colors-m3-on-secondary-fixed008: #1D192B14;
  --colors-m3-on-secondary-fixed012: #1D192B1f;
  --colors-m3-on-secondary-fixed038: #1D192B61;
  --colors-m3-on-secondary-fixed090: #1D192Be6;
  --colors-m3-secondary-fixed-dim: #CCC2DC;
  --colors-m3-secondary-fixed-dim008: #CCC2DC14;
  --colors-m3-secondary-fixed-dim012: #CCC2DC1f;
  --colors-m3-secondary-fixed-dim038: #CCC2DC61;
  --colors-m3-secondary-fixed-dim090: #CCC2DCe6;
  --colors-m3-on-secondary-fixed-variant: #4A4458;
  --colors-m3-on-secondary-fixed-variant008: #4A445814;
  --colors-m3-on-secondary-fixed-variant012: #4A44581f;
  --colors-m3-on-secondary-fixed-variant038: #4A445861;
  --colors-m3-on-secondary-fixed-variant090: #4A4458e6;
  --colors-m3-tertiary: #EFB8C8;
  --colors-m3-tertiary008: #EFB8C814;
  --colors-m3-tertiary012: #EFB8C81f;
  --colors-m3-tertiary038: #EFB8C861;
  --colors-m3-tertiary090: #EFB8C8e6;
  --colors-m3-on-tertiary: #492532;
  --colors-m3-on-tertiary008: #49253214;
  --colors-m3-on-tertiary012: #4925321f;
  --colors-m3-on-tertiary038: #49253261;
  --colors-m3-on-tertiary090: #492532e6;
  --colors-m3-tertiary-container: #633B48;
  --colors-m3-tertiary-container008: #633B4814;
  --colors-m3-tertiary-container012: #633B481f;
  --colors-m3-tertiary-container038: #633B4861;
  --colors-m3-tertiary-container090: #633B48e6;
  --colors-m3-on-tertiary-container: #FFD8E4;
  --colors-m3-on-tertiary-container008: #FFD8E414;
  --colors-m3-on-tertiary-container012: #FFD8E41f;
  --colors-m3-on-tertiary-container038: #FFD8E461;
  --colors-m3-on-tertiary-container090: #FFD8E4e6;
  --colors-m3-tertiary-fixed: #FFD8E4;
  --colors-m3-tertiary-fixed008: #FFD8E414;
  --colors-m3-tertiary-fixed012: #FFD8E41f;
  --colors-m3-tertiary-fixed038: #FFD8E461;
  --colors-m3-tertiary-fixed090: #FFD8E4e6;
  --colors-m3-on-tertiary-fixed: #31111D;
  --colors-m3-on-tertiary-fixed008: #31111D14;
  --colors-m3-on-tertiary-fixed012: #31111D1f;
  --colors-m3-on-tertiary-fixed038: #31111D61;
  --colors-m3-on-tertiary-fixed090: #31111De6;
  --colors-m3-tertiary-fixed-dim: #EFB8C8;
  --colors-m3-tertiary-fixed-dim008: #EFB8C814;
  --colors-m3-tertiary-fixed-dim012: #EFB8C81f;
  --colors-m3-tertiary-fixed-dim038: #EFB8C861;
  --colors-m3-tertiary-fixed-dim090: #EFB8C8e6;
  --colors-m3-on-tertiary-fixed-variant: #633B48;
  --colors-m3-on-tertiary-fixed-variant008: #633B4814;
  --colors-m3-on-tertiary-fixed-variant012: #633B481f;
  --colors-m3-on-tertiary-fixed-variant038: #633B4861;
  --colors-m3-on-tertiary-fixed-variant090: #633B48e6;
  --colors-m3-error: #F2B8B5;
  --colors-m3-error008: #F2B8B514;
  --colors-m3-error012: #F2B8B51f;
  --colors-m3-error038: #F2B8B561;
  --colors-m3-error090: #F2B8B5e6;
  --colors-m3-on-error: #601410;
  --colors-m3-on-error008: #60141014;
  --colors-m3-on-error012: #6014101f;
  --colors-m3-on-error038: #60141061;
  --colors-m3-on-error090: #601410e6;
  --colors-m3-error-container: #8C1D18;
  --colors-m3-error-container008: #8C1D1814;
  --colors-m3-error-container012: #8C1D181f;
  --colors-m3-error-container038: #8C1D1861;
  --colors-m3-error-container090: #8C1D18e6;
  --colors-m3-on-error-container: #F9DEDC;
  --colors-m3-on-error-container008: #F9DEDC14;
  --colors-m3-on-error-container012: #F9DEDC1f;
  --colors-m3-on-error-container038: #F9DEDC61;
  --colors-m3-on-error-container090: #F9DEDCe6;
  --colors-m3-outline: #938F99;
  --colors-m3-outline008: #938F9914;
  --colors-m3-outline012: #938F991f;
  --colors-m3-outline038: #938F9961;
  --colors-m3-outline090: #938F99e6;
  --colors-m3-background: #141218;
  --colors-m3-background008: #14121814;
  --colors-m3-background012: #1412181f;
  --colors-m3-background038: #14121861;
  --colors-m3-background090: #141218e6;
  --colors-m3-on-background: #E6E0E9;
  --colors-m3-on-background008: #E6E0E914;
  --colors-m3-on-background012: #E6E0E91f;
  --colors-m3-on-background038: #E6E0E961;
  --colors-m3-on-background090: #E6E0E9e6;
  --colors-m3-surface: #141218;
  --colors-m3-surface008: #14121814;
  --colors-m3-surface012: #1412181f;
  --colors-m3-surface038: #14121861;
  --colors-m3-surface090: #141218e6;
  --colors-m3-on-surface: #E6E0E9;
  --colors-m3-on-surface008: #E6E0E914;
  --colors-m3-on-surface012: #E6E0E91f;
  --colors-m3-on-surface038: #E6E0E961;
  --colors-m3-on-surface090: #E6E0E9e6;
  --colors-m3-surface-variant: #49454F;
  --colors-m3-surface-variant008: #49454F14;
  --colors-m3-surface-variant012: #49454F1f;
  --colors-m3-surface-variant038: #49454F61;
  --colors-m3-surface-variant090: #49454Fe6;
  --colors-m3-on-surface-variant: #CAC4D0;
  --colors-m3-on-surface-variant008: #CAC4D014;
  --colors-m3-on-surface-variant012: #CAC4D01f;
  --colors-m3-on-surface-variant038: #CAC4D061;
  --colors-m3-on-surface-variant090: #CAC4D0e6;
  --colors-m3-inverse-surface: #E6E0E9;
  --colors-m3-inverse-surface008: #E6E0E914;
  --colors-m3-inverse-surface012: #E6E0E91f;
  --colors-m3-inverse-surface038: #E6E0E961;
  --colors-m3-inverse-surface090: #E6E0E9e6;
  --colors-m3-inverse-on-surface: #322F35;
  --colors-m3-inverse-on-surface008: #322F3514;
  --colors-m3-inverse-on-surface012: #322F351f;
  --colors-m3-inverse-on-surface038: #322F3561;
  --colors-m3-inverse-on-surface090: #322F35e6;
  --colors-m3-inverse-primary: #6750A4;
  --colors-m3-inverse-primary008: #6750A414;
  --colors-m3-inverse-primary012: #6750A41f;
  --colors-m3-inverse-primary038: #6750A461;
  --colors-m3-inverse-primary090: #6750A4e6;
  --colors-m3-shadow: #000000;
  --colors-m3-shadow008: #00000014;
  --colors-m3-shadow012: #0000001f;
  --colors-m3-shadow038: #00000061;
  --colors-m3-shadow090: #000000e6;
  --colors-m3-surface-tint: #D0BCFF;
  --colors-m3-surface-tint008: #D0BCFF14;
  --colors-m3-surface-tint012: #D0BCFF1f;
  --colors-m3-surface-tint038: #D0BCFF61;
  --colors-m3-surface-tint090: #D0BCFFe6;
  --colors-m3-outline-variant: #49454F;
  --colors-m3-outline-variant008: #49454F14;
  --colors-m3-outline-variant012: #49454F1f;
  --colors-m3-outline-variant038: #49454F61;
  --colors-m3-outline-variant090: #49454Fe6;
  --colors-m3-scrim: #000000;
  --colors-m3-scrim008: #00000014;
  --colors-m3-scrim012: #0000001f;
  --colors-m3-scrim038: #00000061;
  --colors-m3-scrim090: #000000e6;
  --colors-m3-surface-container-highest: #36343B;
  --colors-m3-surface-container-highest008: #36343B14;
  --colors-m3-surface-container-highest012: #36343B1f;
  --colors-m3-surface-container-highest038: #36343B61;
  --colors-m3-surface-container-highest090: #36343Be6;
  --colors-m3-surface-container-high: #2B2930;
  --colors-m3-surface-container-high008: #2B293014;
  --colors-m3-surface-container-high012: #2B29301f;
  --colors-m3-surface-container-high038: #2B293061;
  --colors-m3-surface-container-high090: #2B2930e6;
  --colors-m3-surface-container: #211F26;
  --colors-m3-surface-container008: #211F2614;
  --colors-m3-surface-container012: #211F261f;
  --colors-m3-surface-container038: #211F2661;
  --colors-m3-surface-container090: #211F26e6;
  --colors-m3-surface-container-low: #1D1B20;
  --colors-m3-surface-container-low008: #1D1B2014;
  --colors-m3-surface-container-low012: #1D1B201f;
  --colors-m3-surface-container-low038: #1D1B2061;
  --colors-m3-surface-container-low090: #1D1B20e6;
  --colors-m3-surface-container-lowest: #0F0D13;
  --colors-m3-surface-container-lowest008: #0F0D1314;
  --colors-m3-surface-container-lowest012: #0F0D131f;
  --colors-m3-surface-container-lowest038: #0F0D1361;
  --colors-m3-surface-container-lowest090: #0F0D13e6;
  --colors-m3-surface-bright: #3B383E;
  --colors-m3-surface-bright008: #3B383E14;
  --colors-m3-surface-bright012: #3B383E1f;
  --colors-m3-surface-bright038: #3B383E61;
  --colors-m3-surface-bright090: #3B383Ee6;
  --colors-m3-surface-dim: #141218;
  --colors-m3-surface-dim008: #14121814;
  --colors-m3-surface-dim012: #1412181f;
  --colors-m3-surface-dim038: #14121861;
  --colors-m3-surface-dim090: #141218e6;
  --colors-button-elevated-box-shadow: 0px 1px  2px 0px #0000004d, 0px 1px  3px 1px #00000026;
  --shadows-button-elevated-box-shadow: 0px 1px  2px 0px #0000004d, 0px 1px  3px 1px #00000026;
  --shadows-button-elevated-hover-box-shadow: 0px 1px  2px 0px #0000004d, 0px 2px  6px 2px #00000026;
  --shadows-button-tonal-hover-box-shadow: 0px 1px  2px 0px #0000004d, 0px 1px  3px 1px #00000026
}

[data-color-mode=light] {
  --colors-button-filled-color: #FFFFFF;
  --colors-button-filled-disabled-background: #1D1B201f;
  --colors-button-filled-disabled-color: #1D1B2061;
  --colors-button-outlined-border-color: #79747E;
  --colors-button-outlined-hover-background: #6750A414;
  --colors-button-outlined-disabled-border-color: #1D1B201f;
  --colors-button-outlined-disabled-color: #1D1B2061;
  --colors-button-text-hover-background: #6750A414;
  --colors-button-text-disabled-color: #1D1B2061;
  --colors-button-elevated-dark-background: #F7F2FA;
  --colors-button-elevated-hover-background: #6750A414;
  --colors-button-elevated-hover-box-shadow: 0px 2px  6px 2px #00000026, 0px 1px  2px 0px #0000004d;
  --colors-button-elevated-pressed-background: #6750A414;
  --colors-button-elevated-focus-background: #6750A414;
  --colors-button-elevated-disabled-background: #6750A41f;
  --colors-button-elevated-disabled-color: #1D1B2061;
  --colors-button-tonal-color: #1D192B;
  --colors-button-tonal-disabled-background: #6750A41f;
  --colors-button-tonal-disabled-color: #1D1B2061;
  --colors-elevated-background-hover: #49454F14;
  --colors-elevated-border-color: #CAC4D0;
  --colors-surface-medium: #F3EDF7;
  --colors-surface-high: #ECE6F0
}

[data-color-mode=dark] {
  --colors-button-filled-color: #381E72;
  --colors-button-filled-disabled-background: #E6E0E91f;
  --colors-button-filled-disabled-color: #E6E0E961;
  --colors-button-outlined-border-color: #938F99;
  --colors-button-outlined-hover-background: #D0BCFF14;
  --colors-button-outlined-disabled-border-color: #E6E0E91f;
  --colors-button-outlined-disabled-color: #E6E0E961;
  --colors-button-text-hover-background: #D0BCFF14;
  --colors-button-text-disabled-color: #E6E0E961;
  --colors-button-elevated-dark-background: #1D1B20;
  --colors-button-elevated-hover-background: #D0BCFF14;
  --colors-button-elevated-hover-box-shadow: 0px 1px  2px 0px #0000004d, 0px 2px  6px 2px #00000026;
  --colors-button-elevated-pressed-background: #D0BCFF14;
  --colors-button-elevated-focus-background: #D0BCFF14;
  --colors-button-elevated-disabled-background: #D0BCFF1f;
  --colors-button-elevated-disabled-color: #E6E0E961;
  --colors-button-tonal-color: #E8DEF8;
  --colors-button-tonal-disabled-background: #D0BCFF1f;
  --colors-button-tonal-disabled-color: #E6E0E961;
  --colors-elevated-background-hover: #CAC4D014;
  --colors-elevated-border-color: #49454F;
  --colors-surface-medium: #211F26;
  --colors-surface-high: #2B2930
}
  }
  